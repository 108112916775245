<template>
  <div>
    <div v-for="item in this.list" :key="item[nameKey]">
      <div class="no-wrap">
        <div class="row" v-if="item[nameKey] != null">
          <span class="col-2 text-bold q-mr-md" style="font-size: 17px">
            {{ `${item[nameKey]} :` }}
          </span>
          <span
            class="col-6"
            style="font-size: 17px; white-space: pre-wrap"
            v-text="item[valueKey]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProBaselineGrid",
  props: {
    list: {
      type: Array,
      required: true,
    },
    nameKey: {
      type: String,
      default: "key",
    },

    valueKey: {
      type: String,
      default: "value",
    },
  },
};
</script>
<style scoped></style>
