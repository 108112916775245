<template>
  <pro-menu-layout>
    <div class="q-ma-md">
      <pro-deck
        :title="`${$t('Form.Field.NDAResponses')} - ${$route.params.companyName}`"
        :cards="ndaSubmissions"
        v-slot="{ card: submission }"
      >
        <pro-deck-card
          :title="$proSmart.common.getFormattedDate(submission.dateReceived)"
          :status="submission.status ? submission.status : null"
          :status-color="submission.status === 'Rejected' ? 'red' : 'green'"
          expandable
        >
          <template v-slot:action>
            <pro-button
              label="System.Button.Download"
              icon="PROSmart-download"
              @click.stop="handleDownload(submission.submittedFileId)"
            />
          </template>

          <pro-baseline-grid :list="[{key: $t('CustMenu.Col.UploadedBy'), value: submission.createdBy}]" />
        </pro-deck-card>
      </pro-deck>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProButton from "@/components/PROSmart/ProButton";
import ProBaselineGrid from "@/components/PROSmart/ProBaselineGrid";

export default {
  components: { ProMenuLayout, ProDeck, ProDeckCard, ProButton, ProBaselineGrid },
  data() {
    return {
      ndaSubmissions: [],
      ndaSubmissionRecord: [],
    };
  },
  methods: {
    handleDownload(fileId) {
      this.$proSmart.download.downloadFile(this, this.$route.params.id, fileId);
    },
  },
  created() {
    this.$proSmart.documentUi
      .getNdaSubmissionByTendererId(
        this,
        this.$route.params.id,
        this.$route.params.tendererId
      )
      .then((submission) => {
        this.ndaSubmissions = submission.map((nda, index) => ({
          key: index,
          id: nda.id,
          tenderId: nda.tenderId,
          tendererId: nda.tendererId,
          companyName: nda.companyName,
          tendererUserId: nda.tendererUserId,
          dateReceived: nda.dateReceived,
          submitted: nda.submitted,
          submittedFileId: nda.submittedFileId,
          status: nda.status,
          createdBy: nda.createdByUserName,
        }));
      });
  },
};
</script>
